import styled from "styled-components";
import Heading from "../Text/Heading";
import ICarouselDTO from "../../../Models/DTOs/ICarouselDTO";
import CarouselItemPortrait from "../../Carousels/CarouselItemPortrait";
import useWindowDimensions from "../../../Helpers/UseWindowDimensions";
import {HeadingType} from "../../../Models/Enums/HeadingType";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: calc(48rem/16);
    h3 {
        margin-top: 0;
    }
`;

const PortraitImageContainer = styled.div<{shrink: boolean}>`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: flex-start;
`;

export default function PortraitList({carousel, height, mobileHeight, shrink}: {carousel: ICarouselDTO, height: number, mobileHeight: number, shrink: boolean}){
    const { width } = useWindowDimensions(),
        daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Sunday'];

    return (
        <Container>
            <Heading type={ HeadingType.H3 }>
                { carousel.Title }
            </Heading>

            <PortraitImageContainer shrink={shrink}>
                {carousel.Content.map((content, index) => {
                    return <CarouselItemPortrait height={width > 600 ? height : mobileHeight} key={index.toString() + content.Id} Content={content} releaseDayOfWeek={ daysOfWeek[index % daysOfWeek.length] } />;
                })}
            </PortraitImageContainer>
        </Container>
    );
}