import moment from "moment";
import lscache from "lscache";

import { ContentAccessType } from "../Models/Enums/ContentAccessType";
import { ContentType } from "../Models/Enums/ContentType";
import { PlanPeriod } from "../Models/Enums/PlanPeriod";
import { PlanType } from "../Models/Enums/PlanType";
import { SubscriptionStatus } from "../Models/Enums/SubscriptionStatus";
import { Originals } from "../Models/Enums/SearchFilters/Originals";
import { FilterBy } from "../Models/Enums/SearchFilters/FilterBy";
import { SortBy } from "../Models/Enums/SearchFilters/SortBy";
import { ISearchFilterDTO } from "../Models/DTOs/ISearchFilterDTO";
import { HelpSubject } from "../Models/Enums/HelpSubject";
import { RoutePaths } from "../Constants/RoutePaths";
import { PagesWithoutNavigation } from "../Constants/PagesWithoutNavigation";
import { PagesWithoutFooter } from "../Constants/PagesWithoutFooter";
import { TvPlatform } from "../Models/Enums/TvPlatforms";
import CryptoJS from "crypto-js";
import BadWords from "../Assets/BadWords.json";
import { PanelSelector } from "../Models/Enums/PanelSelector";
import { IUserDetails } from "../Models/IUserDetails";
import { GetLandingPanel, GetPanels } from "../Api/Panel";
import { PagesWithoutPopups } from "../Constants/PagesWithoutPopups";
import { LocalStorageKeys } from "../Constants/LocalStorageKeys";
import { toast } from "react-toastify";
import { Location } from "react-router-dom";
import config from "../Constants/Config";

export function IsDev() {
    if (!process.env.REACT_APP_ENVIRONMENT || process.env.REACT_APP_ENVIRONMENT === "development") {

        // development build code
        return true;

    } else {

        // production build code
        return false;
    }
}

export function SetTitle(title: string) {

    if (title === "") {
        document.title = "Ickonic";
        return;
    }

    let oldTitle = document.title;
    let newtitle = oldTitle.split("-")[0] + " - " + title;

    document.title = newtitle;
}

export function AddToPersistentStorage(key: string, value: Object | string, time: number = 0) {
    if (time <= 0) {
        lscache.set(key, value);
    } else {
        lscache.set(key, value, time);
    }
}

export function debounce(func: (...args: any[]) => void, delay: number) {
    let debounceTimer: NodeJS.Timeout;
    return (...args: any[]) => {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func(...args), delay);
    };
};

export function GetContentRedirect(contentType: ContentType, id: number) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.Watch(id);
        case ContentType.Series:
            return RoutePaths.SeriesEpisodes(id);
        case ContentType.Event:
            return RoutePaths.Event(id);
        case ContentType.Category:
            return RoutePaths.Category(id);
        default:
            return null;
    }
}

export function GetTrailerRoute(contentId: number, contentType: ContentType) {
    switch (contentType) {
        case ContentType.Film:
        case ContentType.Episode:
            return RoutePaths.WatchTrailer(contentId);
        case ContentType.Series:
            return RoutePaths.SeriesTrailer(contentId);
        default:
            return RoutePaths.Browse;
    }
}

export function convertUTCDateToLocalDate(date: Date): string {
    let stillUtc = moment.utc(date).toDate();
    let local = moment(stillUtc).local().format("hh:mm A DD/MM/yyyy");
    return local;
}

export function IsUuid(text: string) {
    const regex = new RegExp(
        "/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i"
    );
    return regex.test(text);
}

export function IsCommentValid(text: string) {
    const regex = new RegExp(
        "^((?!(?:http(s)?:\\/\\/)?[\\w.-]+(?:\\.[\\w\\.-]+)+[\\w\\-\\._~:\\/?#[\\]@!\\$&'\\(\\)\\*\\+,;=.]+).)([A-Za-z0-9!\\-.,;_#£&$%?\\u00C0-\\u00D6\\u00D8-\\u00f6\\u00f8-\\u00ff\\s]*)$",
        "gm"
    );

    return regex.test(text);
}

export function ContainsBadWords(text: string) {
    if (text.length <= 0) {
        return false;
    }

    const badWords = BadWords as string[];
    const contains = badWords.some((x) => text.split(/\s+/).includes(x));

    return contains;
}

export function GetAccessLevelTitle(accessLevel: number) {
    switch (accessLevel) {
        case 5:
        case 1:
            return "Two Devices";
        case 4:
        case 2:
            return "One Device";
        case 3:
            return "Four Devices";
        case 99:
            return "Admin";
        default:
            return "None";
    }
}

export function PlanTypeText(accessLevel: number) {
    switch (accessLevel) {
        case 1:
            return "Standard";
        case 2:
            return "Basic";
        case 3:
            return "Premium";
        case 99:
            return "Admin";
        default:
            return "Just David Icke";
    }
}

export function SymbolFromCurrency(currency?: string) {
    const gbp = "\u00A3";

    if (currency === null || currency === undefined || currency.length <= 0) {
        // GBP
        return gbp;
    }

    switch (currency.toUpperCase()) {
        case "USD":
            return "\u0024";
        case "NZD":
            return "NZ \u0024";
        case "AUD":
            return "AU \u0024";
        case "CAD":
            return "C \u0024";
        case "EUR":
            return "\u20AC";
        case "JPY":
            return "\u00A5";
        case "SEK":
            return "SEK";
        default:
            // GBP
            return gbp;
    }
}

export function PlanInterval(billingFrequency: number) {
    switch (billingFrequency) {
        case 10:
            return "One off";
        case 1:
            return "Year";
        case 2:
            return "Quarter";
        default:
            return "Month";
    }
}

export function DeviceLimit(title: string) {
    if (title === null || title.length === 0) {
        return 0;
    }

    switch (title.toLowerCase()) {
        case "premium":
            return 4;
        case "game changer":
        case "standard":
            return 2;
        case "truth seeker":
        case "basic":
            return 1;
        default:
            return 0;
    }
}

export function DeviceNumberToString(deviceNumber: number) {
    switch (deviceNumber) {
        default:
        case 1:
            return "One";
        case 2:
            return "Two";
        case 3:
            return "Three";
        case 4:
            return "Four";
        case 5:
            return "Five";
        case 6:
            return "Six";
    }
}

export function ContainsSpecialChars(str: string) {
    const specialChars = new RegExp(/[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/);
    return specialChars.test(str);
}

export function ContainsNumber(str: string) {
    const specialChars = new RegExp(/\d/);
    return specialChars.test(str);
}

export function ContainsAt(str: string) {
    const specialChars = new RegExp(/@/);
    return specialChars.test(str);
}

export function ContainsUpperCase(str: string) {
    const regex = new RegExp(/[A-Z]/);
    return regex.test(str);
}

export function IsNameValid(text: string) {
    const regex = new RegExp(/^[a-zA-Z- \u00C0-\u00FF]*$/);

    return regex.test(text);
}

export function IsEven(value: number) {
    return value % 2 === 0;
}

export function IsNumber(value?: string) {
    if (value === undefined || value.length <= 0) {
        return false;
    }

    return !isNaN(+value) && isFinite(+value) && !/e/i.test(value);
}

export function IsNullOrWhiteSpace(input: string | undefined) {
    return !input || !input.trim();
}

export function DateAddDays(days: number): Date {
    let date = new Date();
    date.setDate(date.getDate() + days);
    return date;
}

export function GetRenewStatusTitle(subscriptionStatus: SubscriptionStatus) {
    switch (subscriptionStatus) {
        case SubscriptionStatus.Active:
        case SubscriptionStatus.Trialing:
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Cancelled:
        case SubscriptionStatus.Unpaid:
            return SubscriptionStatus[subscriptionStatus];
        case SubscriptionStatus.Past_Due:
            return "Past Due";
        case SubscriptionStatus.Incomplete_Expired:
            return "Incomplete Expired";
        default:
        case SubscriptionStatus.None:
            return "n/a";
    }
}

export function GetPlanSubText(
    subStatus: SubscriptionStatus,
    endDate: Date,
    billingFrequency: number
) {
    switch (subStatus) {
        case SubscriptionStatus.Trialing:
            return "Your trial ends " + moment(endDate).format("Do MMMM YYYY").toString();
        case SubscriptionStatus.Active:
            return (
                "Your next " +
                PlanInterval(billingFrequency).toLocaleLowerCase() +
                "ly renewal date will be " +
                moment(endDate).format("Do MMMM YYYY").toString()
            );
        case SubscriptionStatus.Past_Due:
        case SubscriptionStatus.Incomplete_Expired:
        case SubscriptionStatus.Incomplete:
        case SubscriptionStatus.Cancelled:
            return (
                "Your subscription will end on " + moment(endDate).format("Do MMMM YYYY").toString()
            );
        case SubscriptionStatus.Unpaid:
        case SubscriptionStatus.None:
        default:
            return "";
    }
}

export function GetPlanPeriod(type: PlanType) {
    switch (type) {
        case PlanType.MonthlyFour:
        case PlanType.MonthlyTwo:
        case PlanType.MonthlyOne:
        case PlanType.TruthSeeker:
            return "Month";
        case PlanType.YearlyFour:
        case PlanType.YearlyTwo:
        case PlanType.YearlyOne:
        case PlanType.GameChanger:
            return "Year";
        case PlanType.Quarterly:
            return "Quarter";
        default:
            return "";
    }
}

export function TrimPlanTitle(title: string) {
    if (title.toLowerCase().includes("monthly")) {
        return title.split("Monthly")[1].trim();
    }
    if (title.toLowerCase().includes("yearly")) {
        return title.split("Yearly")[1].trim();
    }
    if (title.toLowerCase().includes("quarterly")) {
        return title.split("quarterly")[1].trim();
    }

    return title;
}

export function GetPlanType(accessType: ContentAccessType, period: PlanPeriod) {
    switch (accessType) {
        case ContentAccessType.OneDevice:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyOne;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyOne;
                default:
                    return undefined;
            }
        case ContentAccessType.TwoDevices:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyTwo;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyTwo;
                default:
                    return undefined;
            }
        case ContentAccessType.FourDevices:
            switch (period) {
                case PlanPeriod.Monthly:
                    return PlanType.MonthlyFour;
                case PlanPeriod.Quarterly:
                    return PlanType.Quarterly;
                case PlanPeriod.Yearly:
                    return PlanType.YearlyFour;
                default:
                    return undefined;
            }
        case ContentAccessType.GameChanger:
            return PlanType.GameChanger;
        case ContentAccessType.TruthSeeker:
            return PlanType.TruthSeeker;
        case ContentAccessType.Unlimited:
    }
}

export function GetPlanTitle(type: PlanType) {
    switch (type) {
        case PlanType.MonthlyOne:
        case PlanType.YearlyOne:
            return "Basic";
        case PlanType.MonthlyTwo:
        case PlanType.YearlyTwo:
            return "Standard";
        case PlanType.MonthlyFour:
        case PlanType.YearlyFour:
            return "Premium";
        case PlanType.TruthSeeker:
            return "Truth Seeker";
        case PlanType.GameChanger:
            return "Game Changer";
    }
}

export function GetPlanUniqueIdentifier(type: PlanType) {
    switch (type) {
        case PlanType.GameChanger:
            return <span>Best Value</span>;
        default:
            return null
    }
}

export function GetPlanDisplayedPrice(price: number, type: PlanType) {
    let displayPrice : string;
    let period : JSX.Element;
    period = (
        <span>{' / ' + GetPlanPeriod(type) }</span>
    );
    switch (type) {
        case PlanType.GameChanger:
            let newPrice = price / 12;
            displayPrice = newPrice.toFixed(2);
            period = (
                <span>{ ' / month' }</span>
            );
            break;
        default:
            displayPrice = price.toString();
            break;
    }

    return (
        <>
            { displayPrice }
            { period }
        </>
    );
}

export function DifferenceBetweenDates(high: Date, low: Date) {
    let timeDiff = Math.abs(high.getTime() - low.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return diffDays;
}

export function addDays(date: Date, days: number) {
    let newData = new Date();

    newData.setDate(date.getDate() + days);
    return newData;
}

export function IsEligibleForSevenExtraDays(start: Date, startEndDifference: number) {
    let currentDate = new Date();
    let nextSevenDays = addDays(start, 7);
    let nextFourDays = addDays(start, 4);

    if (currentDate < nextSevenDays && currentDate >= nextFourDays && startEndDifference <= 7) {
        return true;
    }

    return false;
}

export function SplitVideoLink(link: string) {
    return link.split("/manifest")[0];
}

export function ShouldSearch(searchTerm: string) {
    return searchTerm.length >= 2;
}

export function GetDefaultSearchFilters(): ISearchFilterDTO {
    let searchFilters: ISearchFilterDTO = {
        SortBy: SortBy.Newest,
        FilterBy: FilterBy.FilmsAndSeries,
        Originals: Originals.All,
    };
    return searchFilters;
}

export function HoursToMinutes(hours: number): number {
    return hours * 60;
}

export function GetPercentageOfNumber(percentage: number, value: number) {
    var percent = (percentage / 100) * value;

    return percent;
}

export function HelpTopicToString(topic: string) {
    switch (topic) {
        case HelpSubject.Careers:
            return "Careers";
        case HelpSubject.ContentSuggestions:
            return "Content Suggestion";
        case HelpSubject.CustomerService:
            return "Customer Service Request";
        case HelpSubject.Other:
            return "Customer Contact - Other";
        case HelpSubject.TechnicalSupport:
            return "Technical Support Request";
        default:
            return "Customer Support Request";
    }
}

export function ShouldHideNavbar(path: string) {
    return PagesWithoutNavigation.includes(path.toLowerCase());
}

export function ShouldHideFooter(path: string) {
    return PagesWithoutFooter.includes(path.toLowerCase());
}

export function NoPopupPage(path: string) {
    return PagesWithoutPopups.includes(path.toLowerCase());
}

export function HowManyDifferentInArray(
    array1: object[] | number[],
    array2: object[] | number[]
): number {
    let differences = 0;

    if (!array1 && array2) {
        return array2.length;
    }
    if (array1 && !array2) {
        return array1.length;
    }
    if (!array1 && !array2) {
        return 0;
    }

    let sorted1 = array1.sort();
    let sorted2 = array2.sort();

    let shortestArray = sorted1;

    let firstArrayIsLonger = sorted1.length > sorted2.length;
    if (firstArrayIsLonger) {
        shortestArray = sorted2;
    }

    for (let i = 0; i < shortestArray.length; i++) {
        if (sorted1[i] !== sorted2[i]) {
            differences++;
        }
    }

    if (array1.length === array2.length) {
        return differences;
    }

    if (firstArrayIsLonger) {
        return differences + (sorted1.length - sorted2.length);
    }

    return differences + (sorted2.length - sorted1.length);
}

export function GetAuthCompleteLink() {
    let returnUrl = "https://" + window.location.hostname + RoutePaths.Renew3DSConfirm;
    if (returnUrl.includes("localhost")) {
        returnUrl = "http://localhost:" + window.location.port + RoutePaths.Renew3DSConfirm;
    }
    return returnUrl;
}

export function GetTvPlatformName(platform: TvPlatform) {
    switch (platform) {
        case TvPlatform.AndroidTv:
            return "Android TV";
        case TvPlatform.FireStick:
            return "Firestick";
        case TvPlatform.Roku:
            return "Roku";
        case TvPlatform.SamsungTv:
            return "Samsung TV";
        default:
            return "TV";
    }
}

export function RedirectStringToRoutePath(text: string) {
    switch (text) {
        case TvPlatform.AndroidTv:
            return RoutePaths.AndroidTV;
        case TvPlatform.FireStick:
            return RoutePaths.Firestick;
        case TvPlatform.Roku:
            return RoutePaths.Roku;
        case TvPlatform.SamsungTv:
            return RoutePaths.SamsungTV;
        default:
            return RoutePaths.GenericTvAuth;
    }
}
export function Encrypt(text: string, passPhrase: string, iterations = 1000) {
    const keySize = 256;
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    if (
        text === null ||
        text === undefined ||
        text.length <= 0 ||
        passPhrase === null ||
        passPhrase === undefined ||
        passPhrase.length <= 0
    ) {
        return "";
    }

    const key = CryptoJS.PBKDF2(passPhrase, salt, {
        iterations,
        keySize: keySize / 8,
    });

    const encrypted = CryptoJS.AES.encrypt(text, key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    });

    const base = window.btoa(salt.toString() + iv.toString() + encrypted.toString());
    return base;
}

export function Decrypt(encryptText: string, passPhrase: string, iterations = 1000) {
    const keySize = 256;

    if (encryptText === null || encryptText === undefined || encryptText.length <= 0) {
        return "";
    }

    const unbased = window.atob(encryptText);
    const salt = CryptoJS.enc.Hex.parse(unbased.substr(0, 32));
    const iv = CryptoJS.enc.Hex.parse(unbased.substr(32, 32));
    const encrypted = unbased.substring(64);

    const key = CryptoJS.PBKDF2(passPhrase, salt, {
        iterations,
        keySize: keySize / 8,
    });

    const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

export function primitiveToBoolean(value: string | number | boolean | null | undefined): boolean {
    if (typeof value === "string") {
        return value.toLowerCase() === "true" || !!+value; // here we parse to number first
    }

    return !!value;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function isValidId(value: string) {
    let number = Number(value);
    if (isNaN(number)) {
        return false;
    }

    if (number % 1 === 0) {
        return true;
    }

    return false;
}

export async function GetCorrectPanelType(
    selector: PanelSelector,
    userDetails: IUserDetails,
    controller: AbortController
) {
    switch (selector) {
        case PanelSelector.Generic:
            return await GetPanels(true, userDetails.CurrentCountryCode, controller);
        case PanelSelector.Browse:
        case PanelSelector.DavidIcke:
        case PanelSelector.Films:
        case PanelSelector.Series:
        case PanelSelector.NewsAndCurrentAffairs:
            return await GetLandingPanel(selector, controller);
        default:
            break;
    }
}

export function showMarketingPopup(): boolean {
    const cookieName = 'ickonic_marketing_21042023';

    const from = '22/04/2023 18:00';
    const to = '25/04/2023 00:00';
    let isPopUpAvailable = IsDateBetweenRange(from, to);

    if (hasCookie(cookieName) || !isPopUpAvailable) {
        return false;
    }
    addCookie(cookieName, "");
    return true;
}

export function IsDateBetweenRange(start: string, end: string): boolean {
    const now = moment();
    const isBefore = moment(start, 'DD/MM/YYYY hh:mm A').isBefore(now);
    const isAfter = moment(end, 'DD/MM/YYYY hh:mm A').isAfter(now);

    if (isBefore && isAfter) {
        return true;
    }

    return false;
}

export function hasCookie(cookieName: string): boolean {
    var dc = document.cookie;
    var prefix = cookieName + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin === -1) {
        begin = dc.indexOf(prefix);
        if (begin !== 0) return false;
    }
    else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end === -1) {
            end = dc.length;
        }
    }
    return true;
}

export function GetCookieValue(cookieName: string): string {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (cookieName === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return "";
}

export function addCookie(cookieName: string, cookieValue: string) {
    document.cookie = cookieName + "=" + cookieValue;
}

export function addCookieWithExpiry(
    cookieName: string,
    cookieValue: string,
    cookieExpiryHrs: number
) {
    const d = new Date();
    d.setTime(d.getTime() + cookieExpiryHrs * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";";
}


export function ShowWelcomeToast(
    firstName: string | undefined,
    location: Location
) {
    let message;
    const dateString = GetCookieValue("registered");
    const registered =
        dateString === "" ? DateAddDays(-999) : new Date(Date.parse(dateString));
    if (
        location.pathname.toLowerCase().includes("browse") &&
        registered < DateAddDays(-0.25)
    ) {
        firstName === null || firstName === undefined || firstName === ""
            ? (message = "Welcome back!")
            : (message = "Welcome back, " + firstName + "!");
        if (!hasCookie("welcome_back_toast")) {
            toast(message, {
                position: "top-center",
            });
            addCookieWithExpiry("welcome_back_toast", "true", 6);
        }
    }
}

export function ContentTypeToString(contentType: ContentType) {
    switch (contentType) {
        case ContentType.Article:
            return "article";
        case ContentType.Episode:
            return "episode";
        case ContentType.Category:
            return "category";
        case ContentType.Event:
            return "event";
        case ContentType.Film:
            return "films";
        case ContentType.Series:
            return "series";
        default:
            return "content";
    }
}

export const emailValidation = (emailAddress: string): string => {
    if (!emailAddress) {
        return "Email address is required";
    }
    if (!emailAddress.includes('@')) {
        return "Email must contain '@' symbol";
    }
    const [localPart, domain] = emailAddress.split('@');
    if (!localPart || !domain) {
        return "Email must have a local part and domain";
    }
    if (!domain.includes('.') || domain.endsWith('.')) {
        return "Email domain must contain at least one '.'";
    }
    const domainParts = domain.split('.');
    if (domainParts.length < 2 || domainParts.some(part => part.length === 0)) {
        return "Email domain format is invalid";
    }
    // Add more specific checks as needed
    return "valid";
}
export const passwordValidation = (password: string): string => {
    if (!password) {
        return "Password is required";
    }
    if (password.length < 8) {
        return "Password must be at least 8 characters long";
    }
    if (!/[A-Z]/.test(password)) {
        return "Password must contain at least one uppercase letter";
    }
    if (!/[a-z]/.test(password)) {
        return "Password must contain at least one lowercase letter";
    }
    if (!/[0-9]/.test(password)) {
        return "Password must contain at least one number";
    }
    if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password)) {
        return "Password must contain at least one special character";
    }
    return "valid";
};
export const nameValidation = (name: string): string => {
    if (!name || name.trim().length === 0) {
        return "Name is required";
    }
    if (!name || name.trim().length < 2) {
        return "Name should be at least 2 characters long";
    }
    return "valid";
};
export const zipValidation = (name: string): string => {
    if (!name || name.trim().length === 0) {
        return "Zip required";
    }
    return "valid";
};
export const setFormError = (setErrors: React.Dispatch<React.SetStateAction<any>>, field: string, value: string) => {
    setErrors((prevErrors: any) => ({
        ...prevErrors,
        [field]: value,
    }));
};

export function SetLoginAfterToNow() {
    AddToPersistentStorage(LocalStorageKeys.LoginAfter, moment());
}


export function BSTGMTCheck(date: Date): boolean {
    const inputDate = moment(date);
    const isBST = inputDate.isDST();
    return isBST;
}

export function CanUpgrade(userDetails: IUserDetails) {
    const disableUpgrade =
        (userDetails.ContentAccessType === ContentAccessType.FourDevices && userDetails.Period === PlanPeriod.Yearly) ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Cancelled ||
        userDetails.SubscriptionStatus === SubscriptionStatus.None ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Past_Due ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Paused ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Unpaid ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Incomplete_Expired ||
        userDetails.SubscriptionStatus === SubscriptionStatus.Incomplete ||
        userDetails.ContentAccessType === ContentAccessType.GameChanger;

    return !disableUpgrade;
}

export function GetArticleShareUrl(urlTitle: string) {
    const BACKEND_URL = process.env.REACT_APP_API_URL + config.articleShare;
    return BACKEND_URL + urlTitle 
}
